import React from 'react';
import {
    Page,
    Navbar,
    Block,
    f7,
    BlockTitle,
    List,
    ListInput,
    ListButton, ListItem, Input,
} from 'framework7-react';
import moment from "moment";
import IWAjax from "../components/IWAjax";
import {IWHourPicker, IWNumpad} from "../components/IWComponents";


class InterventoPage extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            data_inizio: "",
            data_fine: "",
            ora_inizio: "",
            ora_fine: "",
            ore_viaggio: 0,
            stato: 1,
            descrizione: "",
            note_multiplo: "",
            info_planning: [],
            errors: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event){
        const target = event.target;
        const value = !target.value ? "" : target.value;
        let name = target.name;
        const self = this;

        console.log(name+" - "+value);

        self.setState({
            [name]: value
        });
    }

    componentDidMount(){
        this.getInfoPlanning();
    }

    getInfoPlanning() {
        const self=this;
        IWAjax({
            app: f7,
            data: {
                idplanning: self.props.idplanning,
            },
            route: "getInfoPlanning",
            success: function (res) {
                if(!res.data) return;
                self.setState({
                    info_planning: res.data.info_planning,
                });
            }
        });
    }

    saveChiusuraIntervento(){
        const self = this;
        let errors=[];
        let data_inizio=self.state.data_inizio;
        if(data_inizio instanceof moment) data_inizio=data_inizio.format("YYYY/MM/DD");
        let data_fine=self.state.data_fine;
        if(data_fine instanceof moment) data_fine=data_fine.format("YYYY/MM/DD");
        let descrizione=this.state.descrizione;
        let ora_inizio=this.state.ora_inizio;
        let ora_fine=this.state.ora_fine;
        let ore_viaggio=this.state.ore_viaggio;
        let note_multiplo=this.state.note_multiplo;
        //validazione input
        if(data_inizio==="") errors.push("Data inizio");
        if(descrizione==="") errors.push("Descrizione int.");
        if(ora_inizio==="") errors.push("Ora inizio");
        if(ora_fine==="") errors.push("Ora fine");
        if(note_multiplo==="" && self.state.info_planning["is_tecnicomultiplo"]>1) errors.push("Note per tecnico multiplo");

        console.log("data_inizio: "+data_inizio+" ora inizio: "+ora_inizio);

        if(errors.length>0){
            self.setState({
                errors: "Compilare correttamente i campi: "+errors.join(", "),
            });
            return;
        }
        let tipo_planning=self.state.info_planning["tipo_planning"];

        IWAjax({
            app: f7,
            route: "saveChiusuraIntervento",
            data: {
                idplanning: self.props.idplanning,
                data_inizio: data_inizio,
                data_fine: data_fine,
                ora_inizio: ora_inizio,
                ora_fine: ora_fine,
                ore_viaggio: ore_viaggio,
                stato: self.state.stato,
                descrizione: descrizione,
                note_chiusura: note_multiplo,
                idtecnico: localStorage.getItem("idanagrafica")
            },
            success: function (res) {
                if(!res.data.ret) {
                    f7.dialog.alert(res.data.msg + " - Errore, operazione non effettuata");
                    return;
                }
                f7.dialog.alert("Operazione effettuata con successo", "", function () {
                    self.props.f7router.navigate("/planning/"+tipo_planning+"/"+moment().format("YYYY-MM-DD")+"/");
                });
            }
        })
    }

    render() {
        const self=this;
        return (
            <Page name="form">
                <Navbar title="Dettagli intervento" backLink="Back"/>
                <BlockTitle>Riepilogo intervento</BlockTitle>
                <Block>
                    <p style={{margin: "0px", fontSize: "120%"}}>{self.state.info_planning["cliente"]}</p>
                    <p style={{margin: "0px", fontSize: "110%"}}>{self.state.info_planning["destinazione"]}</p>
                    <p style={{fontWeight: "bold"}}>{self.state.info_planning["descrizione_stato"]}</p>
                    <p>{self.state.info_planning["descrizione"]}</p>
                    <p>{self.state.info_planning["note"]}</p>
                    <p>{self.state.info_planning["nticket"]}</p>
                    <p>{self.state.info_planning["datarichiesta"]}</p>
                    <p>Scadenza: {self.state.info_planning["datascadenza"]}</p>
                    <p style={{fontWeight: "bold"}}>{self.state.info_planning["is_bloccante"]}</p>
                </Block>

                <BlockTitle>Chiudi intervento</BlockTitle>
                <List noHairlinesMd>
                    <ListItem
                        title="Stato"
                        smartSelect
                        smartSelectParams={{
                            openIn: 'sheet',
                            closeOnSelect: 'true',
                            sheetCloseLinkText: 'Chiudi',
                            on: {
                                change: function () {
                                    self.setState({
                                        stato: this.getValue()
                                    })
                                },
                            }
                        }}
                    >
                        <select name="stato" defaultValue="1">
                            <option key="1" value="1">Chiuso</option>
                            <option key="2" value="2">Chiuso parzialmente</option>
                        </select>
                    </ListItem>

                    <ListInput
                        type="textarea"
                        name="descrizione"
                        label="Descrizione int."
                        placeholder="Inserisci una breve descrizione dell'intervento svolto"
                        onChange={self.handleInputChange}
                        required
                        validate
                    />

                    <ListInput
                        type="textarea"
                        name="note_multiplo"
                        label="Note in caso di tecnico multiplo o ore di manutenzione ordinaria"
                        placeholder="Specificare il nome del/degli tecnico/i presenti e le ore lavorate.
                            Se si sta chiudendo un ticket per cui sono state anche realizzate ore di manutenzione scecificarlo in questo campo."
                        onChange={self.handleInputChange}
                        required
                        validate
                    />

                    <ListInput
                        label="Data inizio"
                        name="data_inizio"
                        type="datepicker"
                        placeholder="Data inizio"
                        clearButton
                        calendarParams={{
                            closeOnSelect: "true",
                            dateFormat: "dd/mm/yyyy",
                            locale: "en-GB",
                            on: {
                                change: function () {
                                    let data_moment = moment(this.value.toLocaleString(), "DD-MM-YYYY, h:mm:ss");
                                    self.setState({
                                        data_inizio: data_moment
                                    });
                                },
                            },
                        }}
                        required
                        validate
                    />

                    <ListInput
                        label="Data fine (se diversa da inizio)"
                        name="data_fine"
                        type="datepicker"
                        placeholder="Data fine"
                        clearButton
                        calendarParams={{
                            closeOnSelect: "true",
                            dateFormat: "dd/mm/yyyy",
                            on: {
                                change: function () {
                                    let data_moment = moment(this.value.toLocaleString(), "DD-MM-YYYY, h:mm:ss");
                                    self.setState({
                                        data_fine: data_moment
                                    });
                                },
                            },
                        }}
                    />
                    <IWHourPicker name="ora_inizio" label="Ora inizio" onChange={this.handleInputChange}/>
                    <IWHourPicker name="ora_fine" label="Ora fine" onChange={this.handleInputChange}/>
                    <IWHourPicker name="ore_viaggio" label="di cui ore di viaggio" onChange={this.handleInputChange}/>

                </List>

                <Block>
                    <span style={{color: "red"}}>{this.state.errors}</span>
                </Block>

                <List noHairlinesMd>
                    <ListButton title="Salva" onClick={() => self.saveChiusuraIntervento()}/>
                </List>

            </Page>
        );
    };
}

export default InterventoPage;