import React from "react";
import {f7} from "framework7-react";

export class IWHourPicker extends React.Component{

    componentDidMount(){
        const self=this;
        f7.picker.create({
            inputEl: '#'+this.props.name,
            rotateEffect: true,
            formatValue: function (values, displayValues) {
                return values[0] + ':' + values[1];
            },
            cols: [
                // Hours
                {
                    values: (function () {
                        var arr = [];
                        for (var i = 0; i <= 23; i++) { arr.push(i); }
                        return arr;
                    })(),
                },
                // Divider
                {
                    divider: true,
                    content: ':'
                },
                // Minutes
                {
                    values: (function () {
                        var arr = [];
                        for (var i = 0; i <= 59; i++) { arr.push(i < 10 ? '0' + i : i); }
                        return arr;
                    })(),
                }
            ],
            on: {
                close: function (picker) {
                    self.props.onChange({
                        target: {
                            value: picker.value,
                            name: self.props.name,
                        }
                    });
                }
            },
        });
    }

    render(){
        return (
            <ul>
                <li className="">
                    <div className="item-content item-input">
                        <div className="item-inner">
                            <div className="item-little item-label">{this.props.label}</div>
                            <div className="item-input-wrap">
                                <input type="text" placeholder={this.props.label} readOnly="readonly"
                                       id={this.props.name} name={this.props.name} onChange={this.props.onChange}/>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        )
    }
}
