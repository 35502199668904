import React from 'react';
import {
    Page,
    Navbar,
    Block,
    Link,
    Toolbar,
    f7, BlockTitle, ListItem, List, Searchbar
} from 'framework7-react';
import moment from "moment";
import IWAjax from "../components/IWAjax";

class PlanningPage extends React.Component{
    constructor(props){
        super(props);

        const now = moment();
        let date;
        if(props.data) date = moment(props.data, "YYYY-MM-DD");
        else date = now;
        this.state = {
            array_planning: [],
            data: date,
            tipo: props.tipo
        };
    }

    openCalendar(){
        const self=this;
        const calendar = f7.calendar.create({
            inputEl: '#calendar-input',
            on: {
                change: function () {
                    let data_moment = moment(this.value.toLocaleString(), "DD-MM-YYYY, h:mm:ss");
                    self.setState({
                        data: data_moment
                    });
                    self.getPlanning();
                },
                closed: function (){
                    this.destroy();
                }
            },
            closeOnSelect:true,
        });
        calendar.open();
    }

    componentDidMount(){
        this.getPlanning();
    }

    addDayToData(nday){
        let data=this.state.data;
        data.add(parseInt(nday), "days");
        this.setState({
            data: data
        });
        this.getPlanning();
    }

    getPlanning() {
        const self=this;
        let array_planning=[];
        IWAjax({
            app: f7,
            data: {
                tipo: self.props.tipo,
                data_da: self.state.data.format("YYYY/MM/DD"),
                idtecnico: localStorage.getItem("idanagrafica")
            },
            route: "getElementiPlanning",
            success: function (res) {
                if(!res.data) return;
                for (let i = 0; i < res.data.planning.length; i++){
                    let planning = res.data.planning[i];

                    let item = {
                        idplanning: planning.idplanning,
                        descrizione_cliente: planning.descrizione_cliente,
                        luogo_planning: planning.luogo_planning,
                        desc_intervento: planning.desc_intervento,
                        datascadenza: "Scad: "+planning.datascadenza
                    };
                    array_planning.push(item);
                }
                self.setState({
                    array_planning: array_planning,
                });
            }
        });
    }

    render() {
        const { array_planning, data } = this.state;
        let nome_pagina="Planning "+(this.props.tipo>0?"Extra":"Manutenzioni");

        return (
            <Page>
                <Navbar title={nome_pagina} backLink="Back" backLinkUrl="/home/" backLinkForce={true}/>
                <Searchbar
                    placeholder="Ricerca"
                    clearButton={true}
                    searchContainer=".search-list"
                    searchIn=".item-title, .item-subtitle, .item-text"
                />
                <BlockTitle>Tecnico: {localStorage.getItem("nome_tecnico")}</BlockTitle>
                <Block>
                    <p>L'elenco mostra gli interventi programmati per un mese a partire dalla data selezionata e tutti gli interventi precedenti non chiusi</p>
                </Block>
                <BlockTitle>Data selezionata: {data.format("DD/MM/YYYY")}</BlockTitle>
                <List mediaList className="search-list searchbar-found">
                    {array_planning.map((planning, index) => (
                        <ListItem
                            key={index}
                            link={"/intervento/" + planning.idplanning +"/"}
                            title={planning.descrizione_cliente}
                            after={planning.datascadenza}
                            subtitle={planning.luogo_planning}
                            text={planning.desc_intervento}
                        />
                    ))}
                </List>
                <Block>
                    {/*spazio per evitare che l'elenco venga coperto dalla toolbar*/}
                    &nbsp;
                </Block>
                <Toolbar tabbar labels position="bottom">
                    <Link tabLink="#tab-1" tabLinkActive text="Sett. prec." iconMaterial="chevron_left" link="#"
                          onClick={() => this.addDayToData(-7)}/>
                    <Link tabLink="#tab-2" tabLinkActive text="Data manuale" iconMaterial="event" link="#"
                          id="calendar-input" onClick={() => this.openCalendar()}/>
                    <Link tabLink="#tab-3" tabLinkActive text="Sett. succ." iconMaterial="chevron_right" link="#"
                          onClick={() => this.addDayToData(7)}/>
                </Toolbar>
            </Page>
        );
    };
}

export default PlanningPage;