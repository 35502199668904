// Import React and ReactDOM
import React from 'react';
import ReactDOM from 'react-dom';

// Import Framework7
import Framework7 from 'framework7/lite-bundle';

// Import Framework7-React Plugin
import Framework7React from 'framework7-react';
import Framework7Keypad from 'framework7-plugin-keypad/dist/framework7-keypad';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';
import 'framework7-plugin-keypad/dist/framework7-keypad.min.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import App Component
import App from '../components/app.jsx';

// Init F7 React Plugin
Framework7.use(Framework7React)
Framework7.use(Framework7Keypad);

// Mount React App
ReactDOM.render(
  React.createElement(App),
  document.getElementById('app'),
);