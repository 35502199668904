import React from 'react';

import {
  f7ready,
  App,
  View,

} from 'framework7-react';

import routes from '../js/routes';
import store from '../js/store';
import Config from "../js/config";

const MyApp = () => {
  const config = new Config();
  // Framework7 Parameters
  const f7params = {
    name: config.nome_app, // App name
    theme: 'auto', // Automatic theme detection
    // App store
    store: store,
    // App routes
    routes: routes,
  };

  f7ready(() => {
    // Call F7 APIs here
  });

  const username = localStorage.getItem("username");
  const token = localStorage.getItem("access_token");

  return (
      <App { ...f7params } >
        {/* Your main view, should have "view-main" class */}
        <View main className="safe-areas" url={username && token ? "/home/" : "/login/"} />
      </App>
  )
}
export default MyApp;