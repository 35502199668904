
import AboutPage from '../pages/about.jsx';
import FormPage from '../pages/form.jsx';
import LoginPage from '../pages/login.jsx';
import DynamicRoutePage from '../pages/dynamic-route.jsx';
import RequestAndLoad from '../pages/request-and-load.jsx';
import NotFoundPage from '../pages/404.jsx';
import TestPage from "../pages/test";
import HomePage from "../pages/home";
import AssistenzaPage from "../pages/assistenza";
import PlanningPage from "../pages/planning";
import InterventoPage from "../pages/intervento";
import ElencoInterventiPage from "../pages/elenco_interventi";
import InterventoEseguitoPage from "../pages/intervento_eseguito";
import TimbraturaPage from "../pages/timbratura";

var routes = [
  {
    path: '/login/',
    component: LoginPage,
  },
  {
    path: '/home/',
    component: HomePage,
  },
  {
    path: '/assistenza/',
    component: AssistenzaPage,
  },
  {
    path: '/planning/:tipo/:data/',
    component: PlanningPage,
  },
  {
    path: '/intervento/:idplanning/',
    component: InterventoPage,
  },
  {
    path: '/elenco-interventi/',
    component: ElencoInterventiPage,
  },
  {
    path: '/intervento-eseguito/:idintervento/',
    component: InterventoEseguitoPage,
  },
  {
    path: '/timbratura/',
    component: TimbraturaPage,
  },
  //pagine di test
  {
    path: '/test/',
    component: TestPage,
  },

  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function ({ router, to, resolve }) {
      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = to.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
            {
              component: RequestAndLoad,
            },
            {
              props: {
                user: user,
              }
            }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
