import React, {useState} from 'react';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link,
    Block,
    BlockTitle,
    List,
    ListItem,
    Panel, View, f7, f7ready
} from 'framework7-react';
import Config from "../js/config";
import moment from "moment";


const HomePage = (props) => {
    f7ready(() => {
        document.getElementById("app").className = localStorage.getItem("global-theme") + " " + localStorage.getItem("color-theme");
    });
    const f7router=props.f7router;
    const config = new Config();
    const [global_theme, setGlobalTheme] = useState(localStorage.getItem("global-theme"));
    const [color_theme, setColorTheme] = useState(localStorage.getItem("color-theme"));

    function changeGlobalTheme(event){
        const target = event.target;
        const value = target.value;
        setGlobalTheme(value);
        document.getElementById("app").className = value + " " + color_theme;
        localStorage.setItem("global-theme", value);
    }

    function changeColorTheme(event){
        const target = event.target;
        const value = target.value;
        setColorTheme(value);
        document.getElementById("app").className = global_theme + " " + value;
        localStorage.setItem("color-theme", value);
    }

    function logout(){
        f7.dialog.confirm("Confermare?", "Logout", function () {
            localStorage.setItem("username", "");
            f7router.navigate("/login/");
        });
    }

    const voci_menu=[
        {key: 1, title: "Planning Manutenzioni ordinarie", url: "/planning/0/"+ moment().format("YYYY-MM-DD") + "/"},
        {key: 2, title: "Planning Manutenzioni extra", url: "/planning/1/"+ moment().format("YYYY-MM-DD") + "/"},
        {key: 3, title: "Nuova Timbratura", url: "/timbratura/"},
        {key: 4, title: "Interventi Inseriti", url: "/elenco-interventi/"},
        {key: 5, title: "Guida", url: "/assistenza/"},
        //{key: 6, title: "Test", url: "/test/"}
    ];

    const global_themes = [
        {theme: "", title: "Standard"},
        {theme: "theme-dark", title: "Dark"},
    ];

    const colors = [
        {theme: "", title: "Standard"},
        {theme: "red", title: "Rosso"},
        {theme: "green", title: "Verde"},
        {theme: "blue", title: "Blu"},
        {theme: "pink", title: "Rosa"},
        {theme: "yellow", title: "Giallo"},
        {theme: "orange", title: "Arancione"},
        {theme: "purple", title: "Viola"},
        {theme: "deeppurple", title: "Viola scuro"},
        {theme: "lightblue", title: "Azzurro"},
        {theme: "teal", title: "Verde Acqua"},
        {theme: "lime", title: "Lime"},
        {theme: "deeporange", title: "Arancione scuro"},
        {theme: "gray", title: "Grigio"},
        {theme: "white", title: "Bianco"},
        {theme: "black", title: "Nero"}
    ];

    return(
      <Page name="home">
          {/* Left panel with cover effect*/}
          <Panel left cover>
              <View>
                  <Page>
                      <Navbar title="Impostazioni Tema"/>
                      <BlockTitle>Impostazioni</BlockTitle>
                      <BlockTitle>Tema grafico</BlockTitle>
                      <List>
                          {global_themes.map((color, index) => (
                              <ListItem
                                  key={"key_global_theme_" + index}
                                  radio
                                  name="tema-radio"
                                  value={color.theme}
                                  title={color.title}
                                  onChange={(e) => changeGlobalTheme(e)}
                                  defaultChecked={color.theme === global_theme}
                              />
                          ))}
                      </List>
                      <BlockTitle>Tema Colori</BlockTitle>
                      <List>
                          {colors.map((color, index) => (
                              <ListItem
                                  key={"key_color_theme_" + index}
                                  radio
                                  name="color-radio"
                                  value={"color-theme-" + color.theme}
                                  title={color.title}
                                  onChange={(e) => changeColorTheme(e)}
                                  defaultChecked={"color-theme-" + color.theme === color_theme}
                              />
                          ))}
                      </List>
                  </Page>
              </View>
          </Panel>

          {/* Top Navbar */}
        <Navbar>
          <NavLeft>
            <Link iconMaterial="menu" panelOpen="left" />
          </NavLeft>
          <NavTitle>{config.nome_app}</NavTitle>
          <NavRight>
            <Link iconMaterial="exit_to_app" onClick={() => logout()} />
          </NavRight>
        </Navbar>
        {/* Page content */}
          <Block strong>
              <BlockTitle>Tecnico: {localStorage.getItem("nome_tecnico")}</BlockTitle>
              <p>Benvenuto nell'applicazione di gestione interventi {config.nome_azienda}</p>
              <p>Visualizza gli interventi pianificati cliccando le voci del menù, oppire seleziona Guida per la guida all'utilizzo dell'applicazione</p>
        </Block>
        <BlockTitle>Menù principale</BlockTitle>
        <List>
            {voci_menu.map(menu => (
                <ListItem key={menu.key} title={menu.title} link={menu.url}/>
            ))}
        </List>
      </Page>

    )
};
export default HomePage;