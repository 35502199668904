import {
    Block,
    BlockFooter,
    BlockHeader,
    BlockTitle,
    Button,
    f7,
    f7ready,
    List, ListInput,
    ListItem,
    Navbar,
    Page
} from "framework7-react";
import React from "react";
import {GMapReact} from "../components/GMapsComponent";
import IWAjax from "../components/IWAjax";
import {IWHourPicker} from "../components/IWComponents";

class TimbraturaPage extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            coordinate_now: {},
            error_posizione: "Posizione non rilevata, attivare il GPS e assegnare i permessi di localizzazione al browser",
            idcommessa: 0,
            idcontratto: 0,
            idrigacontratto: 0,
            ar_commesse: [],
            ar_contratti: [],
            ar_righecontratti: [],
            is_posizioneaperta: 0,
            idgestionepresenze: 0,
            desc_lavoro: "",
            ore_viaggio: 0,
            tipologia_timbratura: 0,
            info_apertura: {},
            indirizzo_appr: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount(){
        const self=this;
        let center_default = {
            lat: 0,
            lng: 0,
        };
        self.setState({
            coordinate_base: center_default,
        });
        self.getCoordinateRilevamento();
        f7ready(() => {
            self.getCommesse();
            self.getInfoApertura();
        });
    }

    handleInputChange(event){
        const target = event.target;
        const value = !target.value ? "" : target.value;
        let name = target.name;
        const self = this;

        self.setState({
            [name]: value
        });
    }

    getCoordinateRilevamento(){
        const self=this;
        navigator.geolocation.getCurrentPosition(
            //callback successo
            function(position) {
                let coordinate = {
                    lat: Number(position.coords.latitude),
                    lng: Number(position.coords.longitude),
                    acc: Number(position.coords.accuracy).toFixed(0)
                };
                IWAjax({
                    app: f7,
                    route: "getIndirizzoApprossimativo",
                    data: {
                        coordinate: coordinate,
                    },
                    showPreloader: false,
                    success: function (res) {
                        self.setState({
                            coordinate_now: coordinate,
                            useragent: navigator.userAgent,
                            indirizzo_appr: res.data.indirizzo_appr,
                            error_posizione: ""
                        });
                    }
                })
            },
            //callback errore
            function (){},
            //parametri
            {maximumAge:0, timeout:10000, enableHighAccuracy: true}
        );
    }

    getCommesse(){
        const self = this;
        IWAjax({
            app: f7,
            route: "getCommesse",
            showPreloader: false,
            success: function (res) {
                let ar_commesse = res.data.ar_commesse;
                self.setState({
                    ar_commesse: ar_commesse,
                });
                self.getContratti();
            }
        })
    }

    getContratti(){
        const self = this;
        IWAjax({
            app: f7,
            route: "getContratti",
            data: {
                idcommessa: self.state.idcommessa,
            },
            showPreloader: false,
            success: function (res) {
                self.setState({
                    ar_contratti: res.data.ar_contratti
                });
                self.getRigheContratto();
            }
        })
    }

    getRigheContratto(){
        const self = this;
        IWAjax({
            app: f7,
            route: "getRigheContratto",
            data: {
                idcontratto: self.state.idcontratto,
            },
            showPreloader: false,
            success: function (res) {
                self.setState({
                    ar_righecontratti: res.data.ar_righe
                });
            }
        })
    }

    getInfoApertura() {
        const self = this;
        IWAjax({
            app: f7,
            route: "getInfoApertura",
            data: {
                idtecnico: localStorage.getItem("idanagrafica"),
            },
            showPreloader: false,
            success: function (res) {
                self.setState({
                    is_posizioneaperta: res.data.is_posizioneaperta,
                    idgestionepresenze: res.data.info_apertura.idgestionepresenze,
                    info_apertura: res.data.info_apertura,
                    idcommessa: res.data.info_apertura.idcommessa,
                    idcontratto: res.data.info_apertura.idcontratto,
                    idrigacontratto: res.data.info_apertura.idrigacontratto,
                    tipologia_timbratura: res.data.info_apertura.tipologia_timbratura
                });
            }
        })
    }

    saveTimbratura(){
        const self = this;
        let errors=[];
        let idtecnico = localStorage.getItem("idanagrafica");
        let is_posizioneaperta = this.state.is_posizioneaperta;
        let idcommessa = this.state.idcommessa;
        let error_posizione = this.state.error_posizione;
        let desc_lavoro = this.state.desc_lavoro;
        let tipologia_timbratura = this.state.tipologia_timbratura;
        //validazione input
        if(!idcommessa) errors.push("Commessa");
        if(!tipologia_timbratura) errors.push("Tipologia ore di lavoro");
        if(is_posizioneaperta && desc_lavoro === "") errors.push("Descrizione lavoro svolto");

        if(error_posizione!==""){
            self.setState({
                errors: error_posizione,
            });
            return;
        }
        if(errors.length>0){
            self.setState({
                errors: "Compilare correttamente i campi: "+errors.join(", "),
            });
            return;
        }
        //al salvataggio ricalcolo posizione
        navigator.geolocation.getCurrentPosition(
            //callback successo
            function(position) {
                let coordinate = {
                    lat: Number(position.coords.latitude),
                    lng: Number(position.coords.longitude),
                    acc: Number(position.coords.accuracy).toFixed(0)
                };
                IWAjax({
                    app: f7,
                    route: "saveTimbratura",
                    data: {
                        idtecnico: idtecnico,
                        is_posizioneaperta: is_posizioneaperta,
                        idcommessa: idcommessa,
                        idcontratto: self.state.idcontratto,
                        idrigacontratto: self.state.idrigacontratto,
                        tipologia_timbratura: tipologia_timbratura,
                        coordinate: coordinate,
                        idgestionepresenze: self.state.idgestionepresenze,
                        desc_lavoro: desc_lavoro,
                        ore_viaggio: self.state.ore_viaggio,
                    },
                    success: function (res) {
                        if(!res.data.ret) {
                            f7.dialog.alert(res.data.msg + " - Errore, operazione non effettuata");
                            return;
                        }
                        f7.dialog.alert("Operazione effettuata con successo", "", function () {
                            self.props.f7router.navigate("/home/");
                        });
                    }
                })
            },
            //callback errore
            function (){ f7.dialog.alert("Impossibile rilevare la posizione - Errore, operazione non effettuata");},
            //parametri
            {maximumAge:0, timeout:10000, enableHighAccuracy: true}
        );

    }

    renderInformazioniApertura() {
        const self = this;
        const {ar_commesse, ar_contratti, ar_righecontratti} = self.state;
        return (
            <React.Fragment>
                <BlockTitle style={{marginTop: "0px"}}>Timbratura ingresso</BlockTitle>
                <List noHairlinesMd>
                    <ListItem
                        key={"commesse_list"}
                        title="Commessa"
                        smartSelect
                        smartSelectParams={{
                            openIn: 'sheet',
                            closeOnSelect: 'true',
                            sheetCloseLinkText: 'Chiudi',
                            on: {
                                change: function () {
                                    self.setState({
                                        idcommessa: this.getValue()
                                    });
                                    self.getContratti();
                                },
                            }
                        }}
                    >
                        <select name="idcommessa" id="idcommessa" value={self.state.idcommessa} defaultValue={self.state.idcommessa}>
                            {ar_commesse.map( (comm, index) => (
                                <option key={"keycommessa_" + index} value={comm.value}>{comm.desc}</option>
                            ) )}
                        </select>
                    </ListItem>
                    <ListItem
                        key={"contratti_list"}
                        title="Contratto Lavoro"
                        smartSelect
                        smartSelectParams={{
                            openIn: 'sheet',
                            closeOnSelect: 'true',
                            sheetCloseLinkText: 'Chiudi',
                            on: {
                                change: function () {
                                    self.setState({
                                        idcontratto: this.getValue()
                                    });
                                    self.getRigheContratto();
                                },
                            }
                        }}
                    >
                        <select name="idcontratto" id="idcontratto" value={self.state.idcontratto} defaultValue={self.state.idcontratto}>
                            {ar_contratti.map( (contr, index) => (
                                <option key={"keyccontratti_" + index} value={contr.value}>{contr.desc}</option>
                            ) )}
                        </select>
                    </ListItem>
                    <ListItem
                        key={"righe_list"}
                        title="Attività contratto"
                        smartSelect
                        smartSelectParams={{
                            openIn: 'sheet',
                            closeOnSelect: 'true',
                            sheetCloseLinkText: 'Chiudi',
                            on: {
                                change: function () {
                                    self.setState({
                                        idrigacontratto: this.getValue()
                                    });
                                },
                            }
                        }}
                    >
                        <select name="idrigacontratto" id="idrigacontratto" value={self.state.idrigacontratto} defaultValue={self.state.idrigacontratto}>
                            {ar_righecontratti.map( (riga, index) => (
                                <option key={"keyrighe_" + index} value={riga.value}>{riga.desc}</option>
                            ) )}
                        </select>
                    </ListItem>
                </List>
                <BlockTitle>Tipologia ore di lavoro</BlockTitle>
                <List noHairlinesMd>
                    <ListItem
                        radio
                        radioIcon="end"
                        title="Ordinario"
                        value={1}
                        name="tipologia_timbratura"
                        onChange={self.handleInputChange}
                    ></ListItem>
                    <ListItem
                        radio
                        radioIcon="end"
                        title="Straordinario"
                        value={2}
                        name="tipologia_timbratura"
                        onChange={self.handleInputChange}
                    ></ListItem>
                    <ListItem
                        radio
                        radioIcon="end"
                        title="Ore di reperibilità"
                        value={3}
                        name="tipologia_timbratura"
                        onChange={self.handleInputChange}
                    ></ListItem>
                </List>
            </React.Fragment>
        );
    }

    renderMap(){
        const self = this;
        return (
            <React.Fragment>
                <BlockTitle style={{marginTop: "0px"}}>Mappa posizione</BlockTitle>
                <BlockHeader style={{marginTop: "0px"}}>Precisione rilevamento {self.state.coordinate_now.acc} m</BlockHeader>
                <GMapReact center={self.state.coordinate_now} zoom={11} icontype={"room"} />
                <Block>
                    Indirizzo approssimativo rilevato: <b>{self.state.indirizzo_appr}</b>
                </Block>
                <Block>
                    <Button fill color="red" onClick={() => self.getCoordinateRilevamento()}>
                        Ricalcola Posizione
                    </Button>
                </Block>
            </React.Fragment>
        )
    }

    renderInformazioniChiusura(){
        const self= this;
        const { info_apertura } = this.state;
        let desc_tipologia_timbr = "Ordinario";
        if(info_apertura.tipologia_timbratura == 2) desc_tipologia_timbr = "Straordinario";
        else if(info_apertura.tipologia_timbratura == 3) desc_tipologia_timbr = "Ore di reperibilità";
        return (
            <React.Fragment>
                <BlockTitle>Riepilogo apertura</BlockTitle>
                <Block style={{margin: "0px"}}>Ora: {info_apertura.ora}</Block>
                <Block style={{margin: "0px"}}>Tipologia ore di lavoro: {desc_tipologia_timbr}</Block>
                <Block style={{margin: "0px"}}>Commessa: {info_apertura.commessa}</Block>
                <Block style={{margin: "0px"}}>Contratto lavoro: {info_apertura.contratto}</Block>
                <Block style={{margin: "0px"}}>Attività contratto: {info_apertura.attivita}</Block>
                <BlockTitle>Timbratura uscita</BlockTitle>
                <List noHairlinesMd>
                    <ListInput
                        type="textarea"
                        name="desc_lavoro"
                        label="Descrizione lavoro svolto"
                        placeholder="Specificare una breve descrizione dei lavori svolti"
                        onChange={self.handleInputChange}
                    />
                    <IWHourPicker name="ore_viaggio" label="Ore viaggio" onChange={this.handleInputChange}/>
                </List>
            </React.Fragment>
        )
    }

    render() {
        const self=this;
        let nome_pagina="Inserisci timbratura";
        return (
            <Page>
                <Navbar title={nome_pagina} backLink="Back" backLinkUrl="/home/" backLinkForce={true}/>
                <BlockTitle>Tecnico: {localStorage.getItem("nome_tecnico")}</BlockTitle>
                <Block>
                    <p>Selezionare il cantiere e indicare eventuali ore di viaggio</p>
                </Block>

                {self.state.is_posizioneaperta ? self.renderInformazioniChiusura() : self.renderInformazioniApertura()}
                {self.renderMap()}

                <Block>
                    <span style={{color: "red"}}>{this.state.errors}</span>
                </Block>

                <Block>
                    <Button fill onClick={() => self.saveTimbratura()}>
                        Salva
                    </Button>
                </Block>

            </Page>
        );
    };
}

export default TimbraturaPage;