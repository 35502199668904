import React, {useState} from 'react';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavTitleLarge,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Button, Panel, View, App, Popup, LoginScreen, LoginScreenTitle, ListInput, ListButton, BlockFooter, f7
} from 'framework7-react';

const TestPage = () => {
    // Login screen demo data
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const alertLoginData = () => {
        f7.dialog.alert('Username: ' + username + '<br>Password: ' + password, () => {
            f7.loginScreen.close();
        });
    }

    return(
      <Page name="home">
          {/* Left panel with cover effect*/}
          <Panel left cover themeDark>
              <View>
                  <Page>
                      <Navbar title="Left Panel"/>
                      <Block>Left panel content goes here</Block>
                  </Page>
              </View>
          </Panel>


          {/* Right panel with reveal effect*/}
          <Panel right reveal themeDark>
              <View>
                  <Page>
                      <Navbar title="Right Panel"/>
                      <Block>Right panel content goes here</Block>
                  </Page>
              </View>
          </Panel>

          {/* Top Navbar */}
        <Navbar large sliding={false}>
          <NavLeft>
            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" />
          </NavLeft>
          <NavTitle sliding>Rietec Mobile</NavTitle>
          <NavRight>
            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="right" />
          </NavRight>
          <NavTitleLarge>Rietec Mobile</NavTitleLarge>
        </Navbar>
        {/* Toolbar */}
        <Toolbar bottom>
          <Link>Left Link</Link>
          <Link>Right Link</Link>
        </Toolbar>
        {/* Page content */}
        <Block strong>
          <p>Here is your blank Framework7 app. Let's see what we have here.</p>
        </Block>
        <BlockTitle>Navigation</BlockTitle>
        <List>
          <ListItem link="/about/" title="About"/>
          <ListItem link="/form/" title="Form"/>
        </List>

        <BlockTitle>Modals</BlockTitle>
        <Block strong>
          <Row>
            <Col width="50">
              <Button fill raised popupOpen="#my-popup">Popup</Button>
            </Col>
            <Col width="50">
              <Button fill raised loginScreenOpen="#my-login-screen">Login Screen</Button>
            </Col>
          </Row>
        </Block>

        <BlockTitle>Panels</BlockTitle>
        <Block strong>
          <Row>
            <Col width="50">
              <Button fill raised panelOpen="left">Left Panel</Button>
            </Col>
            <Col width="50">
              <Button fill raised panelOpen="right">Right Panel</Button>
            </Col>
          </Row>
        </Block>

        <List>
          <ListItem
            title="Dynamic (Component) Route"
            link="/dynamic-route/blog/45/post/125/?foo=bar#about"
          />
          <ListItem
            title="Default Route (404)"
            link="/load-something-that-doesnt-exist/"
          />
          <ListItem
            title="Request Data & Load"
            link="/request-and-load/user/123456/"
          />
        </List>

          {/* Popup */}
          <Popup id="my-popup">
              <View>
                  <Page>
                      <Navbar title="Popup">
                          <NavRight>
                              <Link popupClose>Close</Link>
                          </NavRight>
                      </Navbar>
                      <Block>
                          <p>Popup content goes here.</p>
                      </Block>
                  </Page>
              </View>
          </Popup>
          <LoginScreen id="my-login-screen">
              <View>
                  <Page loginScreen>
                      <LoginScreenTitle>Login</LoginScreenTitle>
                      <List form>
                          <ListInput
                              type="text"
                              name="username"
                              placeholder="Your username"
                              value={username}
                              onInput={(e) => setUsername(e.target.value)}
                          ></ListInput>
                          <ListInput
                              type="password"
                              name="password"
                              placeholder="Your password"
                              value={password}
                              onInput={(e) => setPassword(e.target.value)}
                          ></ListInput>
                      </List>
                      <List>
                          <ListButton title="Sign In" onClick={() => alertLoginData()} />
                          <BlockFooter>
                              Some text about login information.<br />Click "Sign In" to close Login Screen
                          </BlockFooter>
                      </List>
                  </Page>
              </View>
          </LoginScreen>
      </Page>

    )
};
export default TestPage;