import React from 'react';
import {
    Page,
    Navbar,
    Block,
    Link,
    Toolbar,
    f7, BlockTitle, ListItem, List, Searchbar
} from 'framework7-react';
import moment from "moment";
import IWAjax from "../components/IWAjax";

class ElencoInterventiPage extends React.Component{
    constructor(props){
        super(props);

        const now = moment();
        let date;
        if(props.data) date = moment(props.data, "YYYY-MM-DD");
        else date = now;
        this.state = {
            array_interventi: [],
            data: date,
        };
    }

    openCalendar(){
        const self=this;
        const calendar = f7.calendar.create({
            inputEl: '#calendar-input',
            on: {
                change: function () {
                    let data_moment = moment(this.value.toLocaleString(), "DD-MM-YYYY, h:mm:ss");
                    self.setState({
                        data: data_moment
                    });
                    self.getElencoInterventi();
                },
                closed: function (){
                    this.destroy();
                }
            },
            closeOnSelect:true,
        });
        calendar.open();
    }

    componentDidMount(){
        this.getElencoInterventi();
    }

    addDayToData(nday){
        let data=this.state.data;
        data.add(parseInt(nday), "days");
        this.setState({
            data: data
        });
        this.getElencoInterventi();
    }

    getElencoInterventi() {
        const self=this;
        let array_interventi=[];
        IWAjax({
            app: f7,
            data: {
                data_da: self.state.data.format("YYYY/MM/DD"),
                idtecnico: localStorage.getItem("idanagrafica")
            },
            route: "getElencoInterventi",
            success: function (res) {
                if(!res.data) return;
                for (let i = 0; i < res.data.length; i++){
                    let intervento = res.data[i];

                    let item = {
                        iddocumento: intervento.iddocumento,
                        descrizione_cliente: intervento.descrizione_cliente,
                        luogo_intervento: intervento.luogo_intervento,
                        desc_intervento: intervento.desc_intervento,
                        dataintervento: intervento.dataintervento,
                        is_segnalato: intervento.is_segnalato,
                        colore_intervento: intervento.colore_intervento
                    };
                    array_interventi.push(item);
                }
                self.setState({
                    array_interventi: array_interventi,
                });
            }
        });
    }

    render() {
        const { array_interventi, data } = this.state;
        let nome_pagina="Elenco interventi";

        return (
            <Page>
                <Navbar title={nome_pagina} backLink="Back" backLinkUrl="/home/" backLinkForce={true}/>
                <Searchbar
                    placeholder="Ricerca"
                    clearButton={true}
                    searchContainer=".search-list"
                    searchIn=".item-title, .item-subtitle, .item-text"
                />
                <BlockTitle>Tecnico: {localStorage.getItem("nome_tecnico")}</BlockTitle>
                <Block>
                    <p>L'elenco mostra gli interventi svolti nella settimana a partire dalla data selezionata</p>
                </Block>
                <BlockTitle>Data selezionata: {data.format("DD/MM/YYYY")}</BlockTitle>
                <List mediaList className="search-list searchbar-found">
                    {array_interventi.map((intervento, index) => (
                        <ListItem
                            key={index}
                            link={"/intervento-eseguito/" + intervento.iddocumento +"/"}
                            title={intervento.descrizione_cliente}
                            after={intervento.dataintervento}
                            subtitle={intervento.luogo_intervento}
                            text={intervento.desc_intervento}
                            style={{color: intervento.colore_intervento}}
                        />
                    ))}
                </List>
                <Block>
                    {/*spazio per evitare che l'elenco venga coperto dalla toolbar*/}
                    &nbsp;
                </Block>
                <Toolbar tabbar labels position="bottom">
                    <Link tabLink="#tab-1" tabLinkActive text="Sett. prec." iconMaterial="chevron_left" link="#"
                          onClick={() => this.addDayToData(-7)}/>
                    <Link tabLink="#tab-2" tabLinkActive text="Data manuale" iconMaterial="event" link="#"
                          id="calendar-input" onClick={() => this.openCalendar()}/>
                    <Link tabLink="#tab-3" tabLinkActive text="Sett. succ." iconMaterial="chevron_right" link="#"
                          onClick={() => this.addDayToData(7)}/>
                </Toolbar>
            </Page>
        );
    };
}

export default ElencoInterventiPage;