import React from 'react';
import {Page, Navbar, Block, BlockTitle, BlockHeader} from 'framework7-react';

const AssistenzaPage = () => (
  <Page>
    <Navbar title="Guida" backLink="Back" />
      <BlockTitle>Guida all'utilizzo</BlockTitle>
        <Block strong>
            <BlockHeader>Sezioni Planning</BlockHeader>
            <p>Per selezionare la data limite degli interventi da visionare nelle pagine Planning è possibile agire sulle freccie poste nella
                barra in basso o aprire il calendario.
            </p>
            <p>
                Il sistema mostrerà tutti gli interventi pianificati un mese dopo la data selezionata. Se esistono interventi non svolti prima
                della data selezionata verranno mostrati comunque.
            </p>
            <p>
                E' presente in alto una barra di ricerca che permette di filtrare il planning per cliente, indirizzo, numero ticket e descrizione dell'attiviità da svolgere.
            </p>
        </Block>
        <Block strong>
            <BlockHeader>Chiusura intervento</BlockHeader>
            <p>Una volta cliccato sull'intervento da chiudere vi verrà mostrato un riepilogo dello stesso.</p>
            <p>Compilando i campi sottostanti, facendo attenzione agli avvisi sui campi obbligatori, e premendo Salva si potrà
            chiudere parzialmente o definitivamente un intervento. Qualora fosse chiuso definitivamente l'intervento sparirà
            dall'elenco nella sezione Planning.</p>
        </Block>
        <Block strong>
          <BlockHeader>Assistenza tecnica</BlockHeader>
          <p>In caso di errore o dubbi nelle procedure è possibile contattarci al numero di telefono 0331514243</p>
        </Block>
  </Page>
);

export default AssistenzaPage;
