import React from 'react';
import {
    Page,
    Navbar,
    Block,
    f7,
    BlockTitle,
    List,
    ListInput,
    ListButton, ListItem,
} from 'framework7-react';
import moment from "moment";
import IWAjax from "../components/IWAjax";

class InterventoEseguitoPage extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            descrizione: "",
            info_intervento: [],
            errors: "",
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event){
        const target = event.target;
        const value = !target.value ? "" : target.value;
        let name = target.name;
        const self = this;

        self.setState({
            [name]: value
        });
    }

    componentDidMount(){
        this.getInfoIntervento();
    }

    getInfoIntervento() {
        const self=this;
        IWAjax({
            app: f7,
            data: {
                idintervento: self.props.idintervento,
                idtecnico: localStorage.getItem("idanagrafica")
            },
            route: "getInfoIntervento",
            success: function (res) {
                if(!res.data) return;
                self.setState({
                    info_intervento: res.data.info_intervento,
                });
            }
        });
    }

    saveErroreIntervento(){
        const self = this;
        let errors=[];
        let descrizione=this.state.descrizione;
        //validazione input
        if(descrizione==="") errors.push("Errore da segnalare");
        if(errors.length>0){
            self.setState({
                errors: "Compilare correttamente i campi: "+errors.join(", "),
            });
            return;
        }

        IWAjax({
            app: f7,
            route: "saveErroreIntervento",
            data: {
                idintervento: self.props.idintervento,
                idtecnico: localStorage.getItem("idanagrafica"),
                descrizione: descrizione,
            },
            success: function (res) {
                if(!res.data.ret) {
                    f7.dialog.alert(res.data.msg + " - Errore, operazione non effettuata");
                    return;
                }
                f7.dialog.alert("Operazione effettuata con successo", "", function () {
                    self.props.f7router.navigate("/elenco-interventi/");
                });
            }
        })
    }

    render() {
        const self=this;
        let segnalato="";
        if (self.state.info_intervento["is_segnalato"]) {
            segnalato=(<p style={{fontWeight: "bold", color: "red"}}>Intervento già segnalato all'ufficio tecnico, è possibile modificare la segnalazione</p>);
        }
        return (
            <Page name="form">
                <Navbar title="Dettagli intervento" backLink="Back"/>
                <BlockTitle>Riepilogo intervento</BlockTitle>
                <Block>
                    <p style={{margin: "0px", fontSize: "120%"}}>{self.state.info_intervento["cliente"]}</p>
                    <p style={{margin: "0px", fontSize: "110%"}}>{self.state.info_intervento["destinazione"]}</p>
                    <p>{self.state.info_intervento["nticket"]}</p>
                    <p>{self.state.info_intervento["descrizione"]}</p>
                    <p>{self.state.info_intervento["note"]}</p>
                    <p>Data inizio: {self.state.info_intervento["datapartenza"]}</p>
                    <p>Data fine: {self.state.info_intervento["dataarrivo"]}</p>
                    <p>Ora inizio: {self.state.info_intervento["orapartenza"]}</p>
                    <p>Ora fine: {self.state.info_intervento["oraarrivo"]}</p>
                    <p style={{fontWeight: "bold"}}>Stato: {self.state.info_intervento["descrizione_stato"]}</p>
                    {segnalato}
                </Block>

                <BlockTitle>Segnala Errore</BlockTitle>
                <List noHairlinesMd>
                    <ListInput
                        type="textarea"
                        name="descrizione"
                        label="Errore da segnalare"
                        placeholder="Inserisci una breve descrizione dell'errore riscontrato"
                        defaultValue={self.state.info_intervento["descrizione_segnalazione"]}
                        onChange={self.handleInputChange}
                        required
                        validate
                    />
                </List>

                <Block>
                    <span style={{color: "red"}}>{this.state.errors}</span>
                </Block>

                <List noHairlinesMd>
                    <ListButton title="Salva" onClick={() => self.saveErroreIntervento()}/>
                </List>

            </Page>
        );
    };
}

export default InterventoEseguitoPage;