import React from "react";
import GoogleMap from "google-map-react";
import {Icon} from "framework7-react";

const MarkerMaps = ({ icontype }) => <Icon material={icontype}/>;

export class GMapReact extends React.Component {
    render() {
        const { center, zoom, icontype } = this.props;
        return (
            <div style={{ height: '200px', width: '100%' }}>
                <GoogleMap
                    bootstrapURLKeys={{
                        key: "AIzaSyDnisTEYGvzgw5-99WkumjKIhZZu5pLSuo",
                        language: 'it',
                        region: 'it',
                    }}
                    center={center}
                    zoom={zoom}
                >
                    <MarkerMaps
                        lat={center.lat}
                        lng={center.lng}
                        icontype={icontype}
                    />
                </GoogleMap>
            </div>
        );
    }
}