import React, {useState} from 'react';
import {
    Page,
    LoginScreenTitle,
    List,
    ListInput,
    ListButton,
    BlockFooter, f7
} from 'framework7-react';
import axios from "axios";
import IWAjax from "../components/IWAjax";
import Config from "../js/config";
import qs from "qs/dist/qs";

const LoginPage = (props) => {

    // Login screen demo data
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { f7route, f7router } = props;
    const config = new Config();

    function responseLogin(){
        const formData1 =  {
            username: username,
            password: password,
            codicecliente: config.codicecliente
        };
        axios({
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(formData1),
            url: config.iride_url + "/ajax.php?JSON=1&object=IWUser&tipoview=login"
        }).then(res => res.data.ret === "true").then(function(ret){
            if(!ret)
            {
                f7.dialog.alert("Credenziali Errate");
                return false;
            }
            localStorage.setItem("username", username);

            IWAjax({
                app: f7,
                route: "getRietecAppCredenziali",
                success: function (res) {
                    localStorage.setItem("idanagrafica", res.data.idanagrafica);
                    localStorage.setItem("nome_tecnico", res.data.nome_tecnico);
                    return f7router.navigate("/home/");
                }
            });
        })
    }

    return (
        <Page loginScreen>
            <LoginScreenTitle>{config.nome_app} - Login</LoginScreenTitle>
            <List form>
                <ListInput
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={username}
                    onInput={(e) => setUsername(e.target.value)}
                />
                <ListInput
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={password}
                    onInput={(e) => setPassword(e.target.value)}
                />
            </List>
            <List>
                <ListButton title="Log In" onClick={() => responseLogin()}/>
                <BlockFooter>
                    Inserisci nome utente e password fornite dall'amministrazione
                </BlockFooter>
            </List>
        </Page>
    );
};

export default LoginPage;
